<template>
  <div class="body"><br>
    <base-dialog :show="!!error" title="An Error" @close="handleError"><p> {{error}}</p></base-dialog>
    <base-dialog fixed :show="isLoading"  title="Authenticating...">
        <base-spinner ></base-spinner>
    </base-dialog>
    <div class="line-height">
      <base-card>
        <div>
          <div class="text-center">
            <img src="@/assets/dec.png">
            <hr>
            <h2> ESTIMATE</h2>
          </div>
          <form @submit.prevent="login">
            <div class="form-row row">
              <div class="col-lg-12 col-sm-12 col-md-12">
                <label for="userid">User ID</label>
                <input type="text" name="userid" id="userid" class="form-control form-control-sm" v-model.trim="userid">
              </div>
            </div>
            <div class="form-row row">
              <div class="col-lg-12 col-sm-12 col-md-12">
                <label for="password">Password</label>
                <input type="password" name="password" id="password" class="form-control form-control-sm" autocomplete v-model.trim="password">
              </div>
            </div>
            <div class="form-row row">
              <div class="col-lg-12 col-sm-12 col-md-12">
                <base-button mode="btn btn-sm w-100">Login</base-button>
              </div>
            </div>
          </form>
        </div>
         
      </base-card>
    </div>  
  </div>
</template>

<script>


export default {
  name: 'App',
  data(){
      return{
          userid:null,
          password:null,
          isLoading:false,
          error:null,
      }
  },methods:{
     async login(){
         this.isLoading=true;
         try{
           await this.$store.dispatch('login',{userid:this.userid,
          password:this.password});   
          this.$router.replace('/dash')
         }catch(error){
                this.isLoading=false;
                this.error = error.message || 'Failed To Authienticate. Try Again Later'
        }
         
          this.isLoading=false; 
              
      },handleError(){
            this.error=null;
        }
  },created(){
    this.$store.dispatch('tryLogin') 
    if(this.$store.getters.isAuthenticate){
          this.$router.replace('/dash');
        }
  }
}
</script>

<style>
.body{
  background: var(--bluenavy);
  margin:0px;
  padding: 0px;
  height:100vh;
}
.h2,h2{
  color:#BCBABE;
}

.form-row{
  padding: 5px 10px;
}
.form label{
  font-size:1rem;
}

.line-height{
  margin-top:7rem;
}
</style>
